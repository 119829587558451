<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import moment from "moment";
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  page: {
    title: "Scores list",
  },
  head() {
    return {
      title: `Scores`,
    };
  },
  components: {
    Layout,
    PageHeader,
    ExportExcel,
  },
  async created() {
    await this.$store.dispatch("quiz/getAllScores").then( ()=>{}).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    scores() {
      return this.$store.getters["quiz/allScores"];
    },
    rows() {
      return this.scores.length;
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    viewProfile(record,index){
      this.$router.push('/quizes/' + record.quiz.id + '/showAnswer')
    },
  },
  filters:{
    formatDate(value){
      if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
      }
    }
  },
  data: () => ({
    title: "Quizzes Scores",
    items: [
      {
        text: "Work Center",
        href: "/"
      },
      {
        text: "Scores",
        href: "/students/scores"
      }
    ],
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "number",
    sortDesc: false,
    filterOn: ["number","name","description"],
    fields: [
      {
        key: "quiz.name",
        label: "Name",
        sortable: true,
        selected: true
      },
      {
        key: "quiz.quiz_type",
        label: "Type",
        sortable: true,
        selected: true
      },
      {
        key: "student_grade",
        label: "Score",
        sortable: true,
        selected: true
      },
      {
        key: "quiz_grade",
        label: "Fullmark",
        sortable: true,
        selected: true
      },
      {
        key: "timestamp",
        label: "Time",
        sortable: true,
        selected: true
      },
      {
        key: "right_questions_nu",
        label: "Correct answers number",
        sortable: true,
        selected: true
      },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
  })
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                <export-excel :jsonData="scores" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="scores"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="viewProfile"
            >
            <template v-slot:cell(timestamp)="data">
              {{ data.item.timestamp | formatDate }}
            </template>
            <template v-slot:cell(quiz.quiz_type)="data">
              <span class="text-light badge bg-success" v-if="data.item.quiz.quiz_type == 'quiz'">Weekly Quiz</span>
              <span class="text-light badge bg-primary" v-if="data.item.quiz.quiz_type == 'exam'">Monthly Exam</span>
              <span class="text-light badge bg-purple" v-if="data.item.quiz.quiz_type == 'trial'">Trial Exam</span>
              <span class="text-light badge bg-warning" v-if="data.item.quiz.quiz_type == 'homework'">Homework</span>
            </template>
            <template v-slot:cell(student_grade)="data">
              <span class="text-light badge bg-success" v-if="data.item.quiz.quiz_type == 'homework'">
                <i class="fas fa-check"></i>
              </span>
              <span  v-else>{{ data.item.student_grade }}</span>
            </template>
            <template v-slot:cell(right_questions_nu)="data">
              <span class="text-light badge bg-success" v-if="data.item.quiz.quiz_type == 'homework'">
                <i class="fas fa-check"></i>
              </span>
              <span  v-else>{{ data.item.right_questions_nu }}</span>
            </template>
            <template v-slot:cell(quiz_grade)="data">
              <span class="text-light badge bg-success" v-if="data.item.quiz.quiz_type == 'homework'">
                <i class="fas fa-check"></i>
              </span>
              <span  v-else>{{ data.item.quiz_grade }}</span>
            </template>
              <template v-slot:cell(action)="data">
                <b-button
                  variant="primary"
                  size="sm"
                  :to="'/quizes/' + data.item.quiz.id + '/showAnswer'"
                >
                  <i
                    class="mdi mdi-eye mr-2 font-18 vertical-middle iconGray"
                  >
                  </i><span class="font-16">Show</span>
                </b-button>
              </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
